import React from 'react'

// import social icons 
import { AiFillGithub } from 'react-icons/ai'
import { AiFillInstagram } from 'react-icons/ai'
import { AiFillYoutube } from 'react-icons/ai'
import { AiFillTwitterCircle } from 'react-icons/ai'
import { AiFillFacebook } from 'react-icons/ai'

const Footer = () => {
  return (
    <>
      <div className="footer">
        {/* logo */}
        <div className="logo">
          <h2>TSM<span>Codes</span></h2>
          <p>Learning never stops...</p>
        </div>

        {/* middle section  */}
        <p className='copyRight'>Copyright © 2022 TSMCode.com</p>


        {/* social meadia icons  */}
        <div className="icons">
          <a href="https://github.com/TushuMore"><AiFillGithub className='social-media-icon' /></a>
          <a href="https://www.instagram.com/tushu_more/"><AiFillInstagram className='social-media-icon' /></a>
          <a href="https://www.youtube.com/channel/UClbvSldBOdNcno-z-KS-jdA"><AiFillYoutube className='social-media-icon' /></a>
          <a href="https://www.youtube.com/channel/UClbvSldBOdNcno-z-KS-jdA"><AiFillTwitterCircle className='social-media-icon' /></a>
          <a href="https://www.youtube.com/channel/UClbvSldBOdNcno-z-KS-jdA"><AiFillFacebook className='social-media-icon' /></a>
        </div>
      </div>
    </>
  )
}

export default Footer
