import React from 'react'

// import images 
import img1 from '../images/design1.png'
import img2 from '../images/design2.png'
import img3 from '../images/design3.png'
import img4 from '../images/design4.png'
import img5 from '../images/design5.png'

const Projects = () => {
    return (
        <>
            <div className="projects-container">
                <h1 className="main-heading">Our Projects</h1>

                {/* projects boxes */}
                <div className="all-boxes">
                    <div className="set-of-three-box">
                        <div className="single-box">

                            {/* main-img  */}
                            <img src={img1} alt="project1" />

                            {/* description  */}
                            <span className="free-try">Free for once!</span>
                            <p className="heading">Portfolio Website</p>
                            <p className="desc-about-project">We create a simple and attractive Portfolio website without creating any complexity.</p>

                            {/* project-button  */}
                            <button className="create-now-button">Create Now</button>

                        </div>

                        <div className="single-box">

                            {/* main-img  */}
                            <img src={img2} alt="project2" />

                            {/* description  */}
                            <span className="free-try">20% off on first website!</span>
                            <p className="heading">E-commerce Website</p>
                            <p className="desc-about-project">This is one of the most demanding project that we provide to our customer and you can create your first website with 20% off!</p>

                            {/* project-button  */}
                            <button className="create-now-button">Create Now</button>

                        </div>
                        <div className="single-box">

                            {/* main-img  */}
                            <img src={img3} alt="project3" />

                            {/* description  */}
                            <span className="free-try">With some great offers!</span>
                            <p className="heading">College Website</p>
                            <p className="desc-about-project">We provide some great offers to the education instituts. Because they create future of world!</p>

                            {/* project-button  */}
                            <button className="create-now-button">Create Now</button>

                        </div>
                    </div>

                    <div className="set-of-three-box">
                        <div className="single-box">

                            {/* main-img  */}
                            <img src={img5} alt="project5" />

                            {/* description  */}
                            <span className="free-try">Free for once!</span>
                            <p className="heading">Portfolio Website</p>
                            <p className="desc-about-project">We create a simple and attractive Portfolio website without creating any complexity.</p>

                            {/* project-button  */}
                            <button className="create-now-button">Create Now</button>

                        </div>

                        <div className="single-box">

                            {/* main-img  */}
                            <img src={img2} alt="project2" />

                            {/* description  */}
                            <span className="free-try">20% off on first website!</span>
                            <p className="heading">E-commerce Website</p>
                            <p className="desc-about-project">This is one of the most demanding project that we provide to our customer and you can create your first website with 20% off!</p>

                            {/* project-button  */}
                            <button className="create-now-button">Create Now</button>

                        </div>
                        <div className="single-box">

                            {/* main-img  */}
                            <img src={img4} alt="project4" />

                            {/* description  */}
                            <span className="free-try">With some great offers!</span>
                            <p className="heading">College Website</p>
                            <p className="desc-about-project">We provide some great offers to the education instituts. Because they create future of world!</p>

                            {/* project-button  */}
                            <button className="create-now-button">Create Now</button>

                        </div>
                    </div>

                    <div className="set-of-three-box">
                        <div className="single-box">

                            {/* main-img  */}
                            <img src={img1} alt="project1" />

                            {/* description  */}
                            <span className="free-try">Free for once!</span>
                            <p className="heading">Portfolio Website</p>
                            <p className="desc-about-project">We create a simple and attractive Portfolio website without creating any complexity.</p>

                            {/* project-button  */}
                            <button className="create-now-button">Create Now</button>

                        </div>

                        <div className="single-box">

                            {/* main-img  */}
                            <img src={img4} alt="project4" />

                            {/* description  */}
                            <span className="free-try">20% off on first website!</span>
                            <p className="heading">E-commerce Website</p>
                            <p className="desc-about-project">This is one of the most demanding project that we provide to our customer and you can create your first website with 20% off!</p>

                            {/* project-button  */}
                            <button className="create-now-button">Create Now</button>

                        </div>
                        <div className="single-box">

                            {/* main-img  */}
                            <img src={img3} alt="project3" />

                            {/* description  */}
                            <span className="free-try">With some great offers!</span>
                            <p className="heading">College Website</p>
                            <p className="desc-about-project">We provide some great offers to the education instituts. Because they create future of world!</p>

                            {/* project-button  */}
                            <button className="create-now-button">Create Now</button>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Projects
