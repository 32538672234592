import React from 'react'
import Typewriter from 'typewriter-effect'

// Designs
import {Swiper, SwiperSlide} from 'swiper/react';
import { Pagination } from 'swiper';

// import swiper css 
import 'swiper/css'
import 'swiper/css/pagination'

// import images 
import img1 from '../images/design1.png'
import img2 from '../images/design2.png'
import img3 from '../images/design3.png'

// Testimonials
import {FaQuoteRight} from 'react-icons/fa'

const Home = () => {

  return (
    <>
      <div className="home-container" id='home'>

        {/* Left section  */}
        <div className="home-left">

          {/* Home main heading  */}
          <h1>Welcome to<span> TSM<span>Codes</span></span></h1>

          {/* Home running text  */}
            <p className='animated-text'>We are <span><Typewriter className="running-text" options={{ 
              autoStart: true,
              loop: true,
              delay: 20,
              strings: [
                " Web Developer.",
                " Android Developer.",
                " Software Developer.",
                " AI/ML Programmer.",
                " Graphic designer."
              ]
            }} /></span>
            </p>

            {/* Home description */}
            <p className="discription">
              We are developer of all type programming fields like 
              Web Developer, Android Developer, and much more things 
              that we developed for you. We are not only developer we 
              teact programming using online lectures and tutorials.
            </p>

            {/* Home buttons */}
            <div className="home-buttons">
              <button className="btn2 exp">Explore</button>
              <button className="btn2 crt">Create project</button>
            </div>
      </div>
    </div>

    {/* Designs */}

    <h1 className="main-heading">Some Designs</h1>
    <div className="project-container" id='projects'>
        <Swiper 
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            loop={Infinity}   
            // slidesPerView={3}         
            spaceBetween={50}
            breakpoints={
                {
                    1000: {
                        slidesPerView : 2
                    }
                }
            }
            coverflowEffect={
                {
                    rotate:0,
                    stretch:0,
                    depth:100,
                    modifier:2.5
                }
            }
            pagination = {{el:'.swiper-bullets', clickable:true}}

            modules = {[Pagination]}

        className="swiper-container">
            <SwiperSlide className="swiper-slide">
                <img src={img1} alt="one" />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
                <img src={img2} alt="two" />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
                <img src={img3} alt="three" />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
                <img src={img1} alt="four" />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
                <img src={img2} alt="five" />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
                <img src={img3} alt="six" />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
                <img src={img1} alt="four" />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
                <img src={img2} alt="five" />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
                <img src={img3} alt="six" />
            </SwiperSlide>
           
           <div className="swiper-bullets">

           </div>
        </Swiper>
    </div>

    {/* Testimonials */}

    <h1 className="main-heading">Testimonials</h1>
    <div className="testimonial-container" id='tesimonial'>
        <div className="test-box">
            <div className="quote-icon-box">
                <FaQuoteRight className='quote-icon'/>
            </div>

            <div className="opinions">
                <p>I don't have words to thank this man, I'm really grateful to have this channel and website in my daily routine. If you're a mere beginner, then you can trust this guy and can put your time into his content. I can assure you that it'll be worth it.</p>
            </div>

            <div className="names">
                <span className='name'>Rahul Ketkar</span>
                <span className='profesion'>Web Developer</span>
            </div>
        </div>
        <div className="test-box">
        <div className="quote-icon-box">
                <FaQuoteRight className='quote-icon'/>
            </div>

            <div className="opinions">
                <p>For everyone who wants to level up their #Coding and #Dev skills - seriously, this channel is for you! Both basic and advanced stacks are covered on this channel, and one can learn according to his skill levels. And the icing on the cake is, everything is available for free.</p>
            </div>

            <div className="names">
                <span className='name'>Virat Patil</span>
                <span className='profesion'>Web Developer</span>
            </div>
        </div>
    </div>
    </>
  )
}

export default Home




