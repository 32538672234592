import './App.css';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Services from './components/Services';
import Projects from './components/Projects';
import Contact from './components/Contact';

import { BrowserRouter, Routes, Route } from 'react-router-dom'


function App() {
  return (
    <>

      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path={'/'} element={<Home />} />
          <Route path={'/projects'} element={<Projects />} />
          <Route path={'/services'} element={<Services />} />
          <Route path={'/contact'} element={<Contact />} />
        </Routes>
        <Footer />
      </BrowserRouter>


    </>
  );
}

export default App;
