import React from 'react'

const Contact = () => {
    return (
        <>
            <div className="contact-container">
                <h1 className="main-heading">Contact Us</h1>

                {/* main container  */}
                <div className="main-contact-box">
                    <input className='same-input' type="text" placeholder='Name' />
                    <input className='same-input' type="text" placeholder='Email' />
                    <input className='same-input' type="text" placeholder='Subject' />
                    <textarea className='massage-area' placeholder='Massage'></textarea>
                    <button className="contact-button">Submit</button>
                </div>
            </div>
        </>
    )
}

export default Contact
