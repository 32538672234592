import React, { useEffect, useState } from 'react'
import './Main.css'


//  icons import 
// import {BsCodeSlash} from 'react-icons/bs'
import { VscThreeBars } from 'react-icons/vsc'
import { BsFillMoonStarsFill } from 'react-icons/bs'

// import social icons 
import { AiFillGithub } from 'react-icons/ai'
import { AiFillInstagram } from 'react-icons/ai'
import { AiFillYoutube } from 'react-icons/ai'
import { AiFillTwitterCircle } from 'react-icons/ai'
import { AiFillFacebook } from 'react-icons/ai'

// Import link 
import { Link } from 'react-router-dom'




const Navbar = () => {


    // responsive useState 
    const [showMediaIcon, setShowMediaIcon] = useState(false);

    // dark theme toggleFunction, useState and useEffect
    const [theme, setTheme] = useState('light-theme');

    const toggleTheme = () => {
        if (theme === "dark-theme") {
            setTheme("light-theme");
        }
        else {
            setTheme("dark-theme");
        }
    }

    useEffect(() => {
        document.body.className = theme;
    }, [theme]);

    return (
        <>
            <div className="main-navbar">
                <div className="navbar">
                    {/* logo */}
                    <div className="logo">
                        <h2>TSM<span>Codes</span></h2>
                        <p>Learning never stops...</p>
                    </div>

                    <div className={showMediaIcon ? 'nav-comp mobile-version' : 'nav-comp'}>


                        {/* navlist */}
                        <div>
                            <ul className='navlist'>
                                <Link to={'/'} className='navlinks'>Home</Link>
                                <Link to={'/projects'} className='navlinks'>Projects</Link>
                                <Link to={'/services'} className='navlinks'>Services</Link>
                                <Link to={'/contact'} className='navlinks'>Contact</Link>
                                <li className='navlinks'>Who we are?</li>
                            </ul>
                        </div>

                        {/* Dark Mode Icon  */}

                        <div className="dark-mode-icon" onClick={() => toggleTheme()}>
                            <BsFillMoonStarsFill className='moon-icon' />
                        </div>

                        {/* buttons  */}

                        <div className="login-signup-btn">
                            <button className="btn1">Signup</button>
                            <button className="btn1">Login</button>
                        </div>
                    </div>
                    <div className="hamburger" onClick={() => setShowMediaIcon(!showMediaIcon)}>
                        <VscThreeBars />
                    </div>
                </div>


                <hr className="main-hr1" />
                {/* Sub-Navbar  */}
                <div className="sub-navbar">
                    {/* Social meadia icon */}

                    <a href="https://github.com/TushuMore"><AiFillGithub className='social-media-icon' /></a>
                    <a href="https://www.instagram.com/tushu_more/"><AiFillInstagram className='social-media-icon' /></a>
                    <a href="https://www.youtube.com/channel/UClbvSldBOdNcno-z-KS-jdA"><AiFillYoutube className='social-media-icon' /></a>
                    <a href="https://www.youtube.com/channel/UClbvSldBOdNcno-z-KS-jdA"><AiFillTwitterCircle className='social-media-icon' /></a>
                    <a href="https://www.youtube.com/channel/UClbvSldBOdNcno-z-KS-jdA"><AiFillFacebook className='social-media-icon' /></a>

                </div>

            </div>




        </>
    )
}

export default Navbar
