import React from 'react'

// import all icons
import {BiCodeAlt} from 'react-icons/bi'
import {DiReact} from 'react-icons/di'
import {AiFillAndroid} from 'react-icons/ai'
import {AiFillFire} from 'react-icons/ai'
import {AiFillYoutube} from 'react-icons/ai'
import {IoIosColorPalette} from 'react-icons/io'

const Services = () => {
  return (
    <>
      <div className="servises-container">
        <h1 className="main-heading">Our Services</h1>

        {/* Service all boxes  */}
        <div className="all-boxes">

          {/* first  */}

          <div className="set-of-three-box">
            <div className="single-service-box">

              {/* icon and service name*/}
              <AiFillAndroid className='service-icon'/>
              <p className="service-name">Android App Development</p>
              <hr className="service-card-hr" />

              {/* description */}
              <p className='service-description'>
                Our most populer service is Android App Development.
                Wec create social media, business, online payment .etc
              </p>

            </div>
            <div className="single-service-box">

              {/* icon and service name*/}
              <DiReact className='service-icon'/>
              <p className="service-name">Web Development</p>
              <hr className="service-card-hr" />

              {/* description */}
              <p className='service-description'>
                The most demanding service of our us. We provide various type 
                of websites you can check it in projects section.
              </p>
            </div>
            <div className="single-service-box">

              {/* icon and service name*/}
              <BiCodeAlt className='service-icon'/>
              <p className="service-name">Software Development</p>
              <hr className="service-card-hr" />

              {/* description */}
              <p className='service-description'>
                We create software for your bussiness on your need.
                We assure that those softwares can helps you alot.
              </p>
            </div>
          </div>

          {/* second */}

          <div className="set-of-three-box">
            <div className="single-service-box">

              {/* icon and service name*/}
              <AiFillFire className='service-icon'/>
              <p className="service-name">Firewall Development</p>
              <hr className="service-card-hr" />

              {/* description */}
              <p className='service-description'>
                We provide firewall for your application safty that
                your inner transaction can't see by outer persone.
              </p>

            </div>
            <div className="single-service-box">

              {/* icon and service name*/}
              <AiFillYoutube className='service-icon'/>
              <p className="service-name">Free lectures</p>
              <hr className="service-card-hr" />

              {/* description */}
              <p className='service-description'>
                There id our channel on youtube. There you can learn codding
                and become a great programmer. You can check out!
                
              </p>
            </div>
            <div className="single-service-box">

              {/* icon and service name*/}
              <IoIosColorPalette className='service-icon'/>
              <p className="service-name">Graphic Designers</p>
              <hr className="service-card-hr" />

              {/* description */}
              <p className='service-description'>
                All desings are designed by our profetional graphic
                designers that look attractive layouts.
              </p>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default Services
